.projects-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #2A2736;
    color: #ffffff;
}
.projects-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 20px;
    gap: 35px;
}
.projects-header-text-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.projects-page-title {
    font-size: 5vmax;
}
.projects-page-description {
    font-size: 1em;
    line-height: 24px;
}
.projects-grid {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr);
    grid-row-gap: 1rem;
}
.projects-page-footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}
.projects-page-footer-title {
    font-size: 1.75em;
}
.projects-page-footer-description {
    line-height: 24px;
}
@media screen and (min-width:775px) {
    .projects-grid {
        align-items: start;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 2rem;
        grid-row-gap: 2rem;
    }
    .projects-header-text-container {
        padding: 0 2vmax;
    }
}
@media screen and (min-width:1024px) {
    .projects-grid {
        grid-column-gap: 3rem;
        grid-row-gap: 3rem;
    }
    .projects-header-text-container {
        padding: 0 5vmax;
    }
    .projects-page-title {
        font-size: 3em;
    }
    .projects-page-description {
        font-size: 1.25em;
        line-height: 1.35em;
    }
}
@media screen and (min-width:1300px) {
    .projects-header-text-container {
        padding: 0 15vmax;
    }
}