.skill-meter-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}
.skill-name {
    font-size: 3.2vmin;
    margin-bottom: 15px;
}
.skill-meter-progressbar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.technology-logo {
    width: 60px;
    height: 60px;
    object-fit: contain;
}
.CircularProgressbar-path {
    stroke: url(#progress-gradient);
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 0.5s ease 0s;
    transition: stroke-dashoffset 0.5s ease 0s;
}
.CircularProgressbar-trail {
    stroke: #5F5F5F;
    stroke-linecap: butt;
}
.skill-proficiency {
    font-size: 3vmin;
    margin-top: 15px;
}

@media screen and (min-width:480px) {
    .technology-logo {
        width: 80px;
        height: 80px;
        object-fit: contain;
    }
}

@media screen and (min-width:775px) {
    .skill-name {
        margin-bottom: 15px;
    }
    .technology-logo {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    .CircularProgressbar-path {
        stroke: url(#progress-gradient);
        stroke-linecap: round;
        -webkit-transition: stroke-dashoffset 0.5s ease 0s;
        transition: stroke-dashoffset 0.5s ease 0s;
    }
    .CircularProgressbar-trail {
        stroke: #5F5F5F;
        stroke-linecap: butt;
    }
    .skill-proficiency {
        font-size: 1.75em;
        margin-top: 15px;
    }
}
@media screen and (min-width:1024px) {
    .skill-meter-container {
        text-align: center;
    }
    .skill-name {
        margin-bottom: 15px;
    }
    .technology-logo {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }
    .CircularProgressbar-path {
        stroke: url(#progress-gradient);
        stroke-linecap: round;
        -webkit-transition: stroke-dashoffset 0.5s ease 0s;
        transition: stroke-dashoffset 0.5s ease 0s;
    }
    .CircularProgressbar-trail {
        stroke: #5F5F5F;
        stroke-linecap: butt;
    }
    .skill-proficiency {
        font-size: 1.75em;
        margin-top: 15px;
    }
}