.regular-text {
    font-weight: 400;
}

.semibold-text {
    font-weight: 500;
}

.bold-text {
    font-weight: 600;
}

.container-content {
    padding: 20px 5vmin;
}
.button-style {
    outline: none;
    background: transparent;
    border: 0;
    margin: 0;
    cursor: pointer;
    text-decoration: none;
}
@media screen and (min-width: 480px) {
    .container-content {
        padding: 20px 5vmin;
    }
}
@media screen and (min-width: 1024px) {
    .container-content {
        padding: 1vmax 15vmax;
        gap: 10vmax;
    }
}
