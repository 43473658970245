.brands-list-container {
    display: flex;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    gap: 35px;
    padding: 20px 20vmin;
    background-color: #2A2736;
}
.brand-logo {
    display: flex;
    max-height: 18px;
    align-self: center;
    object-fit: contain;
    justify-content: center;
    align-items: center;
}
.wework-logo {}
.soundcloud-logo {}
.nbc-logo {}
.nickelodeon-logo {}
.credijusto-logo {}

@media screen and (min-width: 480px) {
    .brands-list-container {
        padding: 20px 20vmin;
    }
    .brand-logo {
        max-height: 24px;
    }
}
@media screen and (min-width: 1024px) {
    .brands-list-container {
        gap: 35px;
        padding: 40px 20vmin;
    }
    .brand-logo {
        max-height: 41px;
    }
}
@media screen and (min-width: 1300px) {
    .brands-list-container {
        gap: 35px;
        padding: 40px 35vmin;
    }
}