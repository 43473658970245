.sticky-position {
    position: sticky;
    top: 0;
    z-index: 10;
}
.sticky-header-container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
}
.sticky-header-bar {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 5px;
}
.header-element {
    flex: 1;
    display: flex;
}
.header-element:first-child > button {
    margin-right: auto;
}
.header-element:last-child > a {
    margin-left: auto;
}
.menu-icon-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.menu-icon {
    display: flex;
    padding: 0;
}
.menu-icon-image {
    width: 30px;
    height: 24px;
}
.home-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-button,
.home-button:focus,
.home-button:active,
.home-button:visited {
    font-size: 2.1vmax;
    color: #000000;
}
.resource-links {
    display: none;
}
.resource {
    text-decoration: none;
    color: #000000;
}
.resource-links-compact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
}

@media (hover: hover) {
    .resource:hover {
        text-decoration: underline;
    }
}

@media screen and (min-width: 775px) {
    .sticky-header-bar {
        align-items: center;
        padding: 5px 15px;
    }
    .menu-icon-container {
        display: none;
    }
    .home-button-container {
        justify-content: flex-start;
        align-items: center;
    }
    .home-button,
    .home-button:focus,
    .home-button:active,
    .home-button:visited {
        font-size: 1.5em;
        outline: none;
        background: transparent;
        border: 0;
        color: #000000;
        text-align: start;
    }
    .resource-links {
        display: flex;
        justify-content: center;
        gap: 15px;
    }
}