.header-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-image: linear-gradient(60deg, rgba(5,56,255,1.00) 0%, rgba(63,255,198,1.00) 100%);
    background-position: center center;
    padding: 20px 5vmin;
    gap: 5vmax;
    color: #ffffff;
}
.text-container {
    text-align: center;
}
.greeting-text {
    font-size: 2.5vmax;
}
.name-text {
    font-size: 5vmax;
}
.personal-photo {
    max-width: 225px;
    max-height: 225px;
    object-fit: contain;
}
@media screen and (min-width:775px) {
    .header-container {
        display: flex;
        flex-direction: row;
        padding: 20px 5vmax;
    }
    .text-container {
        align-self: center;
        text-align: start;
    }
    .greeting-text {
        font-size: 2.5vmin;
    }
    .name-text {
        font-size: 5vmin;
    }
    .about-me-text {
        line-height: 28px;
    }
    .personal-photo {
        max-width: 325px;
        max-height: 325px;
        object-fit: contain;
    }
}
@media screen and (min-width:1024px) {
    .header-container {
        display: flex;
        flex-direction: row;
        padding: 20px 15vmax;
    }
    .text-container {
        align-self: center;
        text-align: start;
    }
    .greeting-text {
        font-size: 2.5vmin;
    }
    .name-text {
        font-size: 5vmin;
    }
    .about-me-text {
        line-height: 28px;
    }
    .personal-photo {
        max-width: 325px;
        max-height: 325px;
        object-fit: contain;
    }
}
@media screen and (min-width:1300px) {
    .header-container {
        display: flex;
        flex-direction: row;
        padding: 20px 28vmax;
        gap: 5vmax;
        color: #ffffff;
    }
    .text-container {
        align-self: center;
        text-align: start;
    }
    .greeting-text {
        font-size: 2.5vmin;
    }
    .name-text {
        font-size: 5vmin;
    }
    .about-me-text {
        line-height: 28px;
    }
    .personal-photo {
        max-width: 325px;
        max-height: 325px;
        object-fit: contain;
    }
}