.action-button-link {
    display: inline-block;
    color: #ffffff;
}
.action-button-link:link, .action-button-link:active {
    text-decoration: none;
}
.action-button-content {
    display: inline-block;
    font-size: 1.7vmax;
    padding: 10px 20px;
    border-radius: 25px;
    background-color: #B280F5;
}

@media (hover: hover) {
    .action-button-link:hover {
        text-decoration: none;
        opacity: 0.8;
    }
}

@media screen and (min-width: 480px) {
    .action-button-content {
        font-size: 1.2vmax;
        padding: 10px 15px;
        border-radius: 25px;
        background-color: #B280F5;
    }
}
@media screen and (min-width: 1024px) {
    .action-button-content {
        display: inline-block;
        font-size: 2vmin;
        padding: 10px 20px;
        border-radius: 25px;
        background-color: #B280F5;
    }
}