.expertise-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #2A2736;
    color: #ffffff;
}
.expertise-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    gap: 50px;
    width: 320px;
}
.expertise-section {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.expertise-section-text {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
}
.expertise-section-title {
    font-size: 5vmin;
    white-space: pre-line;
}
.expertise-section-description {
    font-size: 3.5vmin;
    line-height: 1.5em;
}
.process-diagram-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.process-diagram-image {
    max-width: 250px;
    object-fit: contain;
}
.process-steps {
    font-size: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 30px;
}
.process-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
}
.step-content {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
/* Stagger the list items */
.process-step:nth-child(1) {
    animation-delay: 0s;
}

.process-step:nth-child(2) {
    animation-delay: 0.4s;  /* Start after first arrow finishes */
}

.process-step:nth-child(3) {
    animation-delay: 0.8s;    /* Start after second arrow finishes */
}
.process-arrow {
    align-self: center;  /* Center the arrow */
    margin-top: 20px;    /* Space between content and arrow */
    margin-left: 20px;   /* Align with icon (adjust as needed) */
}
.process-arrow:nth-of-type(1) {
    animation-delay: 1s;    /* Start after first item */
}

.process-arrow:nth-of-type(2) {
    animation-delay: 2.5s;  /* Start after second item */
}
.arrow-path .return-arrow {
    stroke-width: 2;
}
.return-arrow {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 20px;
}
.step-icon {
    max-width: 75px;
    object-fit: contain;
}
.step-title {
    margin-bottom: 8px;  /* Space between title and description */
    font-size: 1em;    /* Keep your existing font size */
}
.step-description {
    font-size: 0.7em;    /* Smaller text for description */
    opacity: 0.9;        /* Slightly muted text */
    line-height: 1.4;    /* Better readability */
}
.skills-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    overflow: hidden;
}
.skills-disclaimer {
    font-size: 2vmin;
    margin-top: 30px;
}
.expertise-page-footer {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    margin-top: 60px;
}
.expertise-page-footer-title {
    font-size: 1.75em;
}
.expertise-page-footer-description {
    line-height: 24px;
}

@media screen and (min-width:480px) {
    .expertise-content {
        width: 400px;
    }
    .expertise-section-title {
        font-size: 2em;
        white-space: pre-line;
    }
    .expertise-section-description {
        font-size: 1em;
        line-height: 1.5em;
    }
    .process-diagram-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
    .process-diagram-image {
        max-width: 300px;
        object-fit: contain;
    }
    .process-steps {
        font-size: 4vmin;
    }
    .step-icon {
        max-width: 100px;
    }
    .arrow-path .return-arrow {
        stroke-width: 2.5;
    }
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;
        overflow: hidden;
    }
}
@media screen and (min-width:775px) {
    .expertise-content {
        width: 600px;
    }
    .expertise-section-title {
        font-size: 2em;
        white-space: pre-line;
    }
    .process-diagram-section {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 25px;
    }
    .process-diagram-image {
        max-width: 450px;
        object-fit: contain;
    }
    .process-steps {
        font-size: 3vmin;
    }
    .step-icon {
        max-width: 100px;
    }
    .arrow-path .return-arrow {
        stroke-width: 3;
    }
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 3rem;
    }
}
@media screen and (min-width:1024px) {
    .expertise-content {
        width: 550px;
        margin: 40px auto;
    }
    .expertise-section-title {
        font-size: 3.5vmin;
        white-space: pre-line;
    }
    .expertise-section-description {
        font-size: 2vmin;
        line-height: 1.5em;
    }
    .process-diagram-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;
    }
    .process-diagram-image {
        max-width: 400px;
        object-fit: contain;
    }
    .process-steps {
        font-size: 2.8vmin;
    }
    .step-icon {
        max-width: 125px;
    }
    .arrow-path .return-arrow {
        stroke-width: 4;
    }
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 2rem;
    }
}
@media screen and (min-width:1300px) {
    .expertise-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        background-color: #2A2736;
        color: #ffffff;
    }
    .expertise-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 40px auto;
        width: 1200px;
    }
    .expertise-section {
        text-align: start;
    }
    .expertise-section-text {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
    }
    .expertise-section-title {
        font-size: 3em;
        white-space: pre-line;
    }
    .expertise-section-description {
        font-size: 1.2em;
        line-height: 1.5em;
    }
    .process-diagram-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .process-diagram-image {
        max-width: 500px;
        object-fit: contain;
    }
    .process-steps {
        font-size: 2em;
        flex-direction: row;
        gap: 40px;
    }
    .process-step {
        flex-direction: column;
    }
    .skills-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 8rem;
    }
    .skills-disclaimer {
        font-size: 1em;
        margin-top: 30px;
    }
    .expertise-page-footer {
        margin-top: 0;
    }
}
